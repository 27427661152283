import axios from "axios";
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { useAuth } from "./AuthProvider";

const Dashpdf = () => {
  const { profile, logOut } = useAuth();
  const [files, setFiles] = useState([]);
  const [images, setImages] = useState(Array(8).fill(null));
  const [pdfs, setPdfs] = useState(Array(8).fill(null));
  const [civs, setCivs] = useState(Array(8).fill(null));
  const [macs, setMacs] = useState(Array(8).fill(null));
  const [extcs, setExtcs] = useState(Array(8).fill(null));
  const [dcivs, setDcivs] = useState(Array(6).fill(null));
  const [dmacs, setDmacs] = useState(Array(6).fill(null));
  const [bscs, setBscs] = useState(Array(6).fill(null));
  const [bscits, setBscits] = useState(Array(6).fill(null));

  useEffect(() => {
    fetchFiles();
  }, []);

  const fetchFiles = async () => {
    const res = await axios.get('https://mitmdb.mitm.ac.in/api/files/files');
    setFiles(res.data);
  };

  const handleImageChange = (index, e) => {
    const newImages = [...images];
    newImages[index] = e.target.files[0];
    setImages(newImages);
  };

  const handlePdfChange = (index, e) => {
    const newPdfs = [...pdfs];
    newPdfs[index] = e.target.files[0];
    setPdfs(newPdfs);
  };

  const handleCivChange = (index, e) => {
    const newCivs = [...civs];
    newCivs[index] = e.target.files[0];
    setCivs(newCivs);
  };

  const handleMacChange = (index, e) => {
    const newMacs = [...macs];
    newMacs[index] = e.target.files[0];
    setMacs(newMacs);
  };

  const handleExtcChange = (index, e) => {
    const newExtcs = [...extcs];
    newExtcs[index] = e.target.files[0];
    setExtcs(newExtcs);
  };

  const handleDcivChange = (index, e) => {
    const newDcivs = [...dcivs];
    newDcivs[index] = e.target.files[0];
    setDcivs(newDcivs);
  };

  const handleDmacChange = (index, e) => {
    const newDmacs = [...dmacs];
    newDmacs[index] = e.target.files[0];
    setDmacs(newDmacs);
  };

  const handleBscChange = (index, e) => {
    const newBscs = [...bscs];
    newBscs[index] = e.target.files[0];
    setBscs(newBscs);
  };

  const handleBscitChange = (index, e) => {
    const newBscits = [...bscits];
    newBscits[index] = e.target.files[0];
    setBscits(newBscits);
  };

  const handleUpload = async () => {
    const formData = new FormData();

    pdfs.forEach((pdf, index) => {
      if (pdf) formData.append(`pdf${index + 1}`, pdf);
    });

    civs.forEach((civ, index) => {
      if (civ) formData.append(`civ${index + 1}`, civ);
    });

    macs.forEach((mac, index) => {
      if (mac) formData.append(`mac${index + 1}`, mac);
    });

    extcs.forEach((extc, index) => {
      if (extc) formData.append(`extc${index + 1}`, extc);
    });

    dcivs.forEach((dciv, index) => {
      if (dciv) formData.append(`dciv${index + 1}`, dciv);
    });

    dmacs.forEach((dmac, index) => {
      if (dmac) formData.append(`dmac${index + 1}`, dmac);
    });

    bscs.forEach((bsc, index) => {
      if (bsc) formData.append(`bsc${index + 1}`, bsc);
    });

    bscits.forEach((bscit, index) => {
      if (bscit) formData.append(`bscit${index + 1}`, bscit);
    });

    await axios.post('https://mitmdb.mitm.ac.in/api/files/upload', formData);
    fetchFiles();
  };

  const handleDelete = async (id, key) => {
    await axios.delete(`https://mitmdb.mitm.ac.in/api/files/files/${id}`, { data: { key } });
    fetchFiles();
  };

  return (
    <div className='mx-3'>
    <div className="h-14 flex justify-between items-center font-change bg-[#c5cbe7] my-4 rounded-t-xl ">
      <h1 className="text-2xl font-semibold text-[#151d3f] px-3">ADMIN PANEL</h1>
      {profile && (
        <div className="flex flex-row justify-center items-center space-x-2 h-14 mx-2 ">
          <img
            src={profile.picture}
            alt={profile.name}
            className="h-10 w-10 rounded-full"
          />
          {/* <span className="font-bold">{profile.name}</span> */}
          <span className="hidden md:block">{profile.email}</span>
          <button
            onClick={logOut}
            className="bg-black my-2 px-6 py-1 text-white rounded-3xl mx-4 hover:bg-gray-800 hover:scale-105 duration-300"
          >
            Logout
          </button>
        </div>
      )}
    </div>

    <div className='mb-14 mx-auto flex justify-center text-sm md:text-base my-4'>
    <Link to='/dashboard' className='md:px-8 py-3 px-4  bg-blue-900 hover:bg-blue-800 text-white rounded-l-lg '>IMAGE's</Link>
    <Link to='/dashpdf' className='md:px-8 py-3 px-4 bg-blue-600  text-white '>PDF's</Link>
    <Link to='/dashnews' className='md:px-8 py-3 px-4 bg-blue-900 hover:bg-blue-800 text-white rounded-r-lg'>News</Link>
    </div>
    <p className='italic text-red-600 text-center'>(Important : Make sure when an uploading pdf the all other fields/Inputs should be clear and upload single document at the time.)</p>
<section>
  <h3 className="text-3xl">Degree Computer Engineering</h3>
{Array.from({ length: 8 }).map((_, index) => (
        <div key={index}>
          <p>semester {index+1}</p>
          <input type="file" accept=".pdf" onChange={(e) => handlePdfChange(index, e)} />
<button onClick={handleUpload} className="bg-blue-900 text-white w-[4rem] py-1 hover:scale-105">Upload</button>
        </div>
      ))}
<div>
        <ul className="flex flex-wrap space-x-3 my-3">
          {files.map(file => (
            <>
              {file.pdf1 && (
                <li key={`${file._id}-pdf1`}>
                  <a href={`https://mitmdb.mitm.ac.in/uploads/${file.pdf1}`} target="_blank" rel="noopener noreferrer">Semester 1</a>
                  <button className="bg-red-700 text-white rounded-md w-[4rem] py-1 hover:scale-105"    onClick={() => handleDelete(file._id, 'pdf1')}>Delete</button>
                </li>
              )}
              {file.pdf2 && (
                <li key={`${file._id}-pdf2`}>
                  <a href={`https://mitmdb.mitm.ac.in/uploads/${file.pdf2}`} target="_blank" rel="noopener noreferrer">Semester 2</a>
                  <button className="bg-red-700 text-white rounded-md w-[4rem] py-1 hover:scale-105"    onClick={() => handleDelete(file._id, 'pdf2')}>Delete</button>
                </li>
              )}
              {file.pdf3 && (
                <li key={`${file._id}-pdf3`}>
                  <a href={`https://mitmdb.mitm.ac.in/uploads/${file.pdf3}`} target="_blank" rel="noopener noreferrer">Semester 3</a>
                  <button className="bg-red-700 text-white rounded-md w-[4rem] py-1 hover:scale-105"    onClick={() => handleDelete(file._id, 'pdf3')}>Delete</button>
                </li>
              )}
              {file.pdf4 && (
                <li key={`${file._id}-pdf4`}>
                  <a href={`https://mitmdb.mitm.ac.in/uploads/${file.pdf4}`} target="_blank" rel="noopener noreferrer">Semester 4</a>
                  <button className="bg-red-700 text-white rounded-md w-[4rem] py-1 hover:scale-105"    onClick={() => handleDelete(file._id, 'pdf4')}>Delete</button>
                </li>
              )}
              {file.pdf5 && (
                <li key={`${file._id}-pdf5`}>
                  <a href={`https://mitmdb.mitm.ac.in/uploads/${file.pdf5}`} target="_blank" rel="noopener noreferrer">Semester 5</a>
                  <button className="bg-red-700 text-white rounded-md w-[4rem] py-1 hover:scale-105"    onClick={() => handleDelete(file._id, 'pdf5')}>Delete</button>
                </li>
              )}
                            {file.pdf6 && (
                <li key={`${file._id}-pdf6`}>
                  <a href={`https://mitmdb.mitm.ac.in/uploads/${file.pdf6}`} target="_blank" rel="noopener noreferrer">Semester 6</a>
                  <button className="bg-red-700 text-white rounded-md w-[4rem] py-1 hover:scale-105"    onClick={() => handleDelete(file._id, 'pdf6')}>Delete</button>
                </li>
              )}

                {file.pdf7 && (
                <li key={`${file._id}-pdf7`}>
                  <a href={`https://mitmdb.mitm.ac.in/uploads/${file.pdf7}`} target="_blank" rel="noopener noreferrer">Semester 7</a>
                  <button className="bg-red-700 text-white rounded-md w-[4rem] py-1 hover:scale-105"    onClick={() => handleDelete(file._id, 'pdf7')}>Delete</button>
                </li>
              )}

                {file.pdf8 && (
                <li key={`${file._id}-pdf8`}>
                  <a href={`https://mitmdb.mitm.ac.in/uploads/${file.pdf8}`} target="_blank" rel="noopener noreferrer">Semester 8</a>
                  <button className="bg-red-700 text-white rounded-md w-[4rem] py-1 hover:scale-105"    onClick={() => handleDelete(file._id, 'pdf8')}>Delete</button>
                </li>
              )}
            </>
          ))}
        </ul>

        <h3 className="text-3xl">Degree Civil Engineering</h3>
        {Array.from({ length: 8 }).map((_, index) => (
          <div key={index}>
            <p>Semester {index + 1}</p>
            <input type="file" accept=".pdf" onChange={(e) => handleCivChange(index, e)} />
            <button onClick={handleUpload} className="bg-blue-900 text-white w-[4rem] py-1 hover:scale-105">Upload</button>
          </div>
        ))}
<div>
          <ul className="flex flex-wrap space-x-3 my-3">
            {files.map(file => (
              <>
                
                {file.civ1 && (
                  <li key={`${file._id}-civ1`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.civ1}`} target="_blank" rel="noopener noreferrer">Semester 1</a>
                    <button className="bg-red-700 text-white rounded-md w-[4rem] py-1 hover:scale-105"    onClick={() => handleDelete(file._id, 'civ1')}>Delete</button>
                  </li>
                )}
                {file.civ2 && (
                  <li key={`${file._id}-civ2`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.civ2}`} target="_blank" rel="noopener noreferrer">Semester 2</a>
                    <button className="bg-red-700 text-white rounded-md w-[4rem] py-1 hover:scale-105"    onClick={() => handleDelete(file._id, 'civ2')}>Delete</button>
                  </li>
                )}
                {file.civ3 && (
                  <li key={`${file._id}-civ3`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.civ3}`} target="_blank" rel="noopener noreferrer">Semester 3</a>
                    <button className="bg-red-700 text-white rounded-md w-[4rem] py-1 hover:scale-105"    onClick={() => handleDelete(file._id, 'civ3')}>Delete</button>
                  </li>
                )}
                {file.civ4 && (
                  <li key={`${file._id}-civ4`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.civ4}`} target="_blank" rel="noopener noreferrer">Semester 4</a>
                    <button className="bg-red-700 text-white rounded-md w-[4rem] py-1 hover:scale-105"    onClick={() => handleDelete(file._id, 'civ4')}>Delete</button>
                  </li>
                )}
                {file.civ5 && (
                  <li key={`${file._id}-civ5`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.civ5}`} target="_blank" rel="noopener noreferrer">Semester 5</a>
                    <button className="bg-red-700 text-white rounded-md w-[4rem] py-1 hover:scale-105"    onClick={() => handleDelete(file._id, 'civ5')}>Delete</button>
                  </li>
                )}
                {file.civ6 && (
                  <li key={`${file._id}-civ6`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.civ6}`} target="_blank" rel="noopener noreferrer">Semester 6</a>
                    <button className="bg-red-700 text-white rounded-md w-[4rem] py-1 hover:scale-105"    onClick={() => handleDelete(file._id, 'civ6')}>Delete</button>
                  </li>
                )}
                {file.civ7 && (
                  <li key={`${file._id}-civ7`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.civ7}`} target="_blank" rel="noopener noreferrer">Semester 7</a>
                    <button className="bg-red-700 text-white rounded-md w-[4rem] py-1 hover:scale-105"    onClick={() => handleDelete(file._id, 'civ7')}>Delete</button>
                  </li>
                )}
                {file.civ8 && (
                  <li key={`${file._id}-civ8`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.civ8}`} target="_blank" rel="noopener noreferrer">Semester 8</a>
                    <button className="bg-red-700 text-white rounded-md w-[4rem] py-1 hover:scale-105"    onClick={() => handleDelete(file._id, 'civ8')}>Delete</button>
                  </li>
                )}
              </>
            ))}
          </ul>
        </div>

      </div>

      <h3 className="text-3xl">Degree Machanical Engineering</h3>
        {Array.from({ length: 8 }).map((_, index) => (
          <div key={index}>
            <p>Semester {index + 1}</p>
            <input type="file" accept=".pdf" onChange={(e) => handleMacChange(index, e)} />
            <button onClick={handleUpload} className="bg-blue-900 text-white w-[4rem] py-1 hover:scale-105">Upload</button>
          </div>
        ))}
<div>
          <ul className="flex flex-wrap space-x-3 my-3">
            {files.map(file => (
              <>
                
                {file.mac1 && (
                  <li key={`${file._id}-mac1`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.mac1}`} target="_blank" rel="noopener noreferrer">Semester 1</a>
                    <button className="bg-red-700 text-white rounded-md w-[4rem] py-1 hover:scale-105"    onClick={() => handleDelete(file._id, 'mac1')}>Delete</button>
                  </li>
                )}
                {file.mac2 && (
                  <li key={`${file._id}-mac2`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.mac2}`} target="_blank" rel="noopener noreferrer">Semester 2</a>
                    <button className="bg-red-700 text-white rounded-md w-[4rem] py-1 hover:scale-105"    onClick={() => handleDelete(file._id, 'mac2')}>Delete</button>
                  </li>
                )}
                {file.mac3 && (
                  <li key={`${file._id}-mac3`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.mac3}`} target="_blank" rel="noopener noreferrer">Semester 3</a>
                    <button className="bg-red-700 text-white rounded-md w-[4rem] py-1 hover:scale-105"    onClick={() => handleDelete(file._id, 'mac3')}>Delete</button>
                  </li>
                )}
                {file.mac4 && (
                  <li key={`${file._id}-mac4`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.mac4}`} target="_blank" rel="noopener noreferrer">Semester 4</a>
                    <button className="bg-red-700 text-white rounded-md w-[4rem] py-1 hover:scale-105"    onClick={() => handleDelete(file._id, 'mac4')}>Delete</button>
                  </li>
                )}
                {file.mac5 && (
                  <li key={`${file._id}-mac5`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.mac5}`} target="_blank" rel="noopener noreferrer">Semester 5</a>
                    <button className="bg-red-700 text-white rounded-md w-[4rem] py-1 hover:scale-105"    onClick={() => handleDelete(file._id, 'mac5')}>Delete</button>
                  </li>
                )}
                {file.mac6 && (
                  <li key={`${file._id}-mac6`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.mac6}`} target="_blank" rel="noopener noreferrer">Semester 6</a>
                    <button className="bg-red-700 text-white rounded-md w-[4rem] py-1 hover:scale-105"    onClick={() => handleDelete(file._id, 'mac6')}>Delete</button>
                  </li>
                )}
                {file.mac7 && (
                  <li key={`${file._id}-mac7`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.mac7}`} target="_blank" rel="noopener noreferrer">Semester 7</a>
                    <button className="bg-red-700 text-white rounded-md w-[4rem] py-1 hover:scale-105"    onClick={() => handleDelete(file._id, 'mac7')}>Delete</button>
                  </li>
                )}
                {file.mac8 && (
                  <li key={`${file._id}-mac8`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.mac8}`} target="_blank" rel="noopener noreferrer">Semester 8</a>
                    <button className="bg-red-700 text-white rounded-md w-[4rem] py-1 hover:scale-105"    onClick={() => handleDelete(file._id, 'mac8')}>Delete</button>
                  </li>
                )}
              </>
            ))}
          </ul>
        </div>


      <h3 className="text-3xl">Degree Electronic Engineering</h3>
        {Array.from({ length: 8 }).map((_, index) => (
          <div key={index}>
            <p>Semester {index + 1}</p>
            <input type="file" accept=".pdf" onChange={(e) => handleExtcChange(index, e)} />
            <button onClick={handleUpload} className="bg-blue-900 text-white w-[4rem] py-1 hover:scale-105">Upload</button>
          </div>
        ))}
<div>
<ul className="flex flex-wrap space-x-3 my-3">
              {files.map(file => (
              <>
       
                {file.extc1 && (
                  <li key={`${file._id}-extc1`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.extc1}`} target="_blank" rel="noopener noreferrer">Semester 1</a>
                    <button className="bg-red-700 text-white rounded-md w-[4rem] py-1 hover:scale-105"    onClick={() => handleDelete(file._id, 'extc1')}>Delete</button>
                  </li>
                )}
                {file.extc2 && (
                  <li key={`${file._id}-extc2`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.extc2}`} target="_blank" rel="noopener noreferrer">Semester 2</a>
                    <button className="bg-red-700 text-white rounded-md w-[4rem] py-1 hover:scale-105"    onClick={() => handleDelete(file._id, 'extc2')}>Delete</button>
                  </li>
                )}
                {file.extc3 && (
                  <li key={`${file._id}-extc3`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.extc3}`} target="_blank" rel="noopener noreferrer">Semester 3</a>
                    <button className="bg-red-700 text-white rounded-md w-[4rem] py-1 hover:scale-105"    onClick={() => handleDelete(file._id, 'extc3')}>Delete</button>
                  </li>
                )}
                {file.extc4 && (
                  <li key={`${file._id}-extc4`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.extc4}`} target="_blank" rel="noopener noreferrer">Semester 4</a>
                    <button className="bg-red-700 text-white rounded-md w-[4rem] py-1 hover:scale-105"    onClick={() => handleDelete(file._id, 'extc4')}>Delete</button>
                  </li>
                )}
                {file.extc5 && (
                  <li key={`${file._id}-extc5`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.extc5}`} target="_blank" rel="noopener noreferrer">Semester 5</a>
                    <button className="bg-red-700 text-white rounded-md w-[4rem] py-1 hover:scale-105"    onClick={() => handleDelete(file._id, 'extc5')}>Delete</button>
                  </li>
                )}
                {file.extc6 && (
                  <li key={`${file._id}-extc6`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.extc6}`} target="_blank" rel="noopener noreferrer">Semester 6</a>
                    <button className="bg-red-700 text-white rounded-md w-[4rem] py-1 hover:scale-105"    onClick={() => handleDelete(file._id, 'extc6')}>Delete</button>
                  </li>
                )}
                {file.extc7 && (
                  <li key={`${file._id}-extc7`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.extc7}`} target="_blank" rel="noopener noreferrer">Semester 7</a>
                    <button className="bg-red-700 text-white rounded-md w-[4rem] py-1 hover:scale-105"    onClick={() => handleDelete(file._id, 'extc7')}>Delete</button>
                  </li>
                )}
                {file.extc8 && (
                  <li key={`${file._id}-extc8`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.extc8}`} target="_blank" rel="noopener noreferrer">Semester 8</a>
                    <button className="bg-red-700 text-white rounded-md w-[4rem] py-1 hover:scale-105"    onClick={() => handleDelete(file._id, 'extc8')}>Delete</button>
                  </li>
                )}
              </>
            ))}
          </ul>

          <h3 className="text-3xl">Diploma Civil Engineering</h3>
        {Array.from({ length: 6 }).map((_, index) => (
          <div key={index}>
            <p>Semester {index + 1}</p>
            <input type="file" accept=".pdf" onChange={(e) => handleDcivChange(index, e)} />
            <button onClick={handleUpload} className="bg-blue-900 text-white w-[4rem] py-1 hover:scale-105">Upload</button>
          </div>
        ))}
<div>
<ul className="flex flex-wrap space-x-3 my-3">
              {files.map(file => (
              <>
       
                {file.dciv1 && (
                  <li key={`${file._id}-dciv1`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.dciv1}`} target="_blank" rel="noopener noreferrer">Semester 1</a>
                    <button className="bg-red-700 text-white rounded-md w-[4rem] py-1 hover:scale-105"    onClick={() => handleDelete(file._id, 'dciv1')}>Delete</button>
                  </li>
                )}
                {file.dciv2 && (
                  <li key={`${file._id}-dciv2`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.dciv2}`} target="_blank" rel="noopener noreferrer">Semester 2</a>
                    <button className="bg-red-700 text-white rounded-md w-[4rem] py-1 hover:scale-105"    onClick={() => handleDelete(file._id, 'dciv2')}>Delete</button>
                  </li>
                )}
                {file.dciv3 && (
                  <li key={`${file._id}-dciv3`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.dciv3}`} target="_blank" rel="noopener noreferrer">Semester 3</a>
                    <button className="bg-red-700 text-white rounded-md w-[4rem] py-1 hover:scale-105"    onClick={() => handleDelete(file._id, 'dciv3')}>Delete</button>
                  </li>
                )}
                {file.dciv4 && (
                  <li key={`${file._id}-dciv4`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.dciv4}`} target="_blank" rel="noopener noreferrer">Semester 4</a>
                    <button className="bg-red-700 text-white rounded-md w-[4rem] py-1 hover:scale-105"    onClick={() => handleDelete(file._id, 'dciv4')}>Delete</button>
                  </li>
                )}
                {file.dciv5 && (
                  <li key={`${file._id}-dciv5`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.dciv5}`} target="_blank" rel="noopener noreferrer">Semester 5</a>
                    <button className="bg-red-700 text-white rounded-md w-[4rem] py-1 hover:scale-105"    onClick={() => handleDelete(file._id, 'dciv5')}>Delete</button>
                  </li>
                )}
                {file.dciv6 && (
                  <li key={`${file._id}-dciv6`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.dciv6}`} target="_blank" rel="noopener noreferrer">Semester 6</a>
                    <button className="bg-red-700 text-white rounded-md w-[4rem] py-1 hover:scale-105"    onClick={() => handleDelete(file._id, 'dciv6')}>Delete</button>
                  </li>
                )}
              </>
            ))}
          </ul>

      </div>


      <h3 className="text-3xl">Diploma Machnical Engineering</h3>
        {Array.from({ length: 6 }).map((_, index) => (
          <div key={index}>
            <p>Semester {index + 1}</p>
            <input type="file" accept=".pdf" onChange={(e) => handleDmacChange(index, e)} />
            <button onClick={handleUpload} className="bg-blue-900 text-white w-[4rem] py-1 hover:scale-105">Upload</button>
          </div>
        ))}
<div>
<ul className="flex flex-wrap space-x-3 my-3">
              {files.map(file => (
              <>
       
       {file.dmac1 && (
                  <li key={`${file._id}-dmac1`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.dmac1}`} target="_blank" rel="noopener noreferrer">Semester 1</a>
                    <button className="bg-red-700 text-white rounded-md w-[4rem] py-1 hover:scale-105"    onClick={() => handleDelete(file._id, 'dmac1')}>Delete</button>
                  </li>
                )}
                {file.dmac2 && (
                  <li key={`${file._id}-dmac2`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.dmac2}`} target="_blank" rel="noopener noreferrer">Semester 2</a>
                    <button className="bg-red-700 text-white rounded-md w-[4rem] py-1 hover:scale-105"    onClick={() => handleDelete(file._id, 'dmac2')}>Delete</button>
                  </li>
                )}
                {file.dmac3 && (
                  <li key={`${file._id}-dmac3`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.dmac3}`} target="_blank" rel="noopener noreferrer">Semester 3</a>
                    <button className="bg-red-700 text-white rounded-md w-[4rem] py-1 hover:scale-105"    onClick={() => handleDelete(file._id, 'dmac3')}>Delete</button>
                  </li>
                )}
                {file.dmac4 && (
                  <li key={`${file._id}-dmac4`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.dmac4}`} target="_blank" rel="noopener noreferrer">Semester 4</a>
                    <button className="bg-red-700 text-white rounded-md w-[4rem] py-1 hover:scale-105"    onClick={() => handleDelete(file._id, 'dmac4')}>Delete</button>
                  </li>
                )}
                {file.dmac5 && (
                  <li key={`${file._id}-dmac5`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.dmac5}`} target="_blank" rel="noopener noreferrer">Semester 5</a>
                    <button className="bg-red-700 text-white rounded-md w-[4rem] py-1 hover:scale-105"    onClick={() => handleDelete(file._id, 'dmac5')}>Delete</button>
                  </li>
                )}
                {file.dmac6 && (
                  <li key={`${file._id}-dmac6`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.dmac6}`} target="_blank" rel="noopener noreferrer">Semester 6</a>
                    <button className="bg-red-700 text-white rounded-md w-[4rem] py-1 hover:scale-105"    onClick={() => handleDelete(file._id, 'dmac6')}>Delete</button>
                  </li>
                )}
              </>
            ))}
          </ul>

      </div>

      <h3 className="text-3xl">Bsc Computer Science</h3>
        {Array.from({ length: 6 }).map((_, index) => (
          <div key={index}>
            <p>Semester {index + 1}</p>
            <input type="file" accept=".pdf" onChange={(e) => handleBscChange(index, e)} />
            <button onClick={handleUpload} className="bg-blue-900 text-white w-[4rem] py-1 hover:scale-105" >Upload</button>
          </div>
        ))}
<div>
<ul className="flex flex-wrap space-x-3 my-3">
              {files.map(file => (
              <>
       
                {file.bsc1 && (
                  <li key={`${file._id}-bsc1`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.bsc1}`} target="_blank" rel="noopener noreferrer">Semester 1</a>
                    <button className="bg-red-700 text-white rounded-md w-[4rem] py-1 hover:scale-105"    onClick={() => handleDelete(file._id, 'bsc1')}>Delete</button>
                  </li>
                )}
                {file.bsc2 && (
                  <li key={`${file._id}-bsc2`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.bsc2}`} target="_blank" rel="noopener noreferrer">Semester 2</a>
                    <button className="bg-red-700 text-white rounded-md w-[4rem] py-1 hover:scale-105"    onClick={() => handleDelete(file._id, 'bsc2')}>Delete</button>
                  </li>
                )}
                {file.bsc3 && (
                  <li key={`${file._id}-bsc3`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.bsc3}`} target="_blank" rel="noopener noreferrer">Semester 3</a>
                    <button className="bg-red-700 text-white rounded-md w-[4rem] py-1 hover:scale-105"    onClick={() => handleDelete(file._id, 'bsc3')}>Delete</button>
                  </li>
                )}
                {file.bsc4 && (
                  <li key={`${file._id}-bsc4`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.bsc4}`} target="_blank" rel="noopener noreferrer">Semester 4</a>
                    <button className="bg-red-700 text-white rounded-md w-[4rem] py-1 hover:scale-105"    onClick={() => handleDelete(file._id, 'bsc4')}>Delete</button>
                  </li>
                )}
                {file.bsc5 && (
                  <li key={`${file._id}-bsc5`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.bsc5}`} target="_blank" rel="noopener noreferrer">Semester 5</a>
                    <button className="bg-red-700 text-white rounded-md w-[4rem] py-1 hover:scale-105"    onClick={() => handleDelete(file._id, 'bsc5')}>Delete</button>
                  </li>
                )}
                {file.bsc6 && (
                  <li key={`${file._id}-bsc6`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.bsc6}`} target="_blank" rel="noopener noreferrer">Semester 6</a>
                    <button className="bg-red-700 text-white rounded-md w-[4rem] py-1 hover:scale-105"    onClick={() => handleDelete(file._id, 'bsc6')}>Delete</button>
                  </li>
                )}
                {file.bsc7 && (
                  <li key={`${file._id}-bsc7`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.bsc7}`} target="_blank" rel="noopener noreferrer">Semester 7</a>
                    <button className="bg-red-700 text-white rounded-md w-[4rem] py-1 hover:scale-105"    onClick={() => handleDelete(file._id, 'bsc7')}>Delete</button>
                  </li>
                )}
                {file.bsc8 && (
                  <li key={`${file._id}-bsc8`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.bsc8}`} target="_blank" rel="noopener noreferrer">Semester 8</a>
                    <button className="bg-red-700 text-white rounded-md w-[4rem] py-1 hover:scale-105"    onClick={() => handleDelete(file._id, 'bsc8')}>Delete</button>
                  </li>
                )}
              </>
            ))}
          </ul>

      </div>


      <h3 className="text-3xl">Bsc Information Technology</h3>
        {Array.from({ length: 6 }).map((_, index) => (
          <div key={index}>
            <p>Semester {index + 1}</p>
            <input type="file" accept=".pdf" onChange={(e) => handleBscitChange(index, e)} />
            <button onClick={handleUpload} className="bg-blue-900 text-white w-[4rem] py-1 hover:scale-105">Upload</button>
          </div>
        ))}
<div>


<ul className="flex flex-wrap space-x-3 my-3">
              {files.map(file => (
              <>
       
                {file.bscit1 && (
                  <li key={`${file._id}-bscit1`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.bscit1}`} target="_blank" rel="noopener noreferrer">Semester 1</a>
                    <button className="bg-red-700 text-white rounded-md w-[4rem] py-1 hover:scale-105"    onClick={() => handleDelete(file._id, 'bscit1')}>Delete</button>
                  </li>
                )}
                {file.bscit2 && (
                  <li key={`${file._id}-bscit2`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.bscit2}`} target="_blank" rel="noopener noreferrer">Semester 2</a>
                    <button className="bg-red-700 text-white rounded-md w-[4rem] py-1 hover:scale-105"    onClick={() => handleDelete(file._id, 'bscit2')}>Delete</button>
                  </li>
                )}
                {file.bscit3 && (
                  <li key={`${file._id}-bscit3`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.bscit3}`} target="_blank" rel="noopener noreferrer">Semester 3</a>
                    <button className="bg-red-700 text-white rounded-md w-[4rem] py-1 hover:scale-105"    onClick={() => handleDelete(file._id, 'bscit3')}>Delete</button>
                  </li>
                )}
                {file.bscit4 && (
                  <li key={`${file._id}-bscit4`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.bscit4}`} target="_blank" rel="noopener noreferrer">Semester 4</a>
                    <button className="bg-red-700 text-white rounded-md w-[4rem] py-1 hover:scale-105"    onClick={() => handleDelete(file._id, 'bscit4')}>Delete</button>
                  </li>
                )}
                {file.bscit5 && (
                  <li key={`${file._id}-bscit5`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.bscit5}`} target="_blank" rel="noopener noreferrer">Semester 5</a>
                    <button className="bg-red-700 text-white rounded-md w-[4rem] py-1 hover:scale-105"    onClick={() => handleDelete(file._id, 'bscit5')}>Delete</button>
                  </li>
                )}
                {file.bscit6 && (
                  <li key={`${file._id}-bscit6`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.bscit6}`} target="_blank" rel="noopener noreferrer">Semester 6</a>
                    <button className="bg-red-700 text-white rounded-md w-[4rem] py-1 hover:scale-105"    onClick={() => handleDelete(file._id, 'bscit6')}>Delete</button>
                  </li>
                )}
                {file.bscit7 && (
                  <li key={`${file._id}-bscit7`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.bscit7}`} target="_blank" rel="noopener noreferrer">Semester 7</a>
                    <button className="bg-red-700 text-white rounded-md w-[4rem] py-1 hover:scale-105"    onClick={() => handleDelete(file._id, 'bscit7')}>Delete</button>
                  </li>
                )}
                {file.bscit8 && (
                  <li key={`${file._id}-bscit8`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.bscit8}`} target="_blank" rel="noopener noreferrer">Semester 8</a>
                    <button className="bg-red-700 text-white rounded-md w-[4rem] py-1 hover:scale-105"    onClick={() => handleDelete(file._id, 'bscit8')}>Delete</button>
                  </li>
                )}
              </>
            ))}
          </ul>

      </div>
      </div>

</section>

    </div>
  )
}

export default Dashpdf