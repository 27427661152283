import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Carousel } from "flowbite-react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion"; 

const events = [
  {
    image: "assets/events/sports/2024/1.jpg",
    title: "Sports 2024",
    description: "Two days Sports Event. Included different Indoor as well as Outdoor Games for all students.",
    link: "/Sports",
  },
  {
    image: "assets/events/tarang/2024/14.jpg",
    title: "Tarang 2024",
    description: "The tarang is very big event in college event.It's one day of event include various dances & acts performs.",
  },
  {
    image: "assets/events/convocation/2024/13.jpg",
    title: "Convocation 2024",
    description: "Every year we celebrate convocation ceremony. For Student who gets graduation from MITM.",
  },
  {
    image: "/assets/events/rex/2024/4.jpg",
    title: "Rex 2024",
    description: "Two days Sports Event. Included different Indoor as well as Outdoor Games for all students. REX-National Project exhibition.100+ projects are being exhibited by students.",
    link: "/Rex&metropulse",
  },
  {
    image: "/assets/events/republic_day/2.jpg",
    title: "Republic day 2024",
    description: "The republic day celebrated in MITM in 2024 with Indian defence force guests.",
  },
  {
    image: "/assets/events/scienceexhibition/9.jpg",
    title: "Science Exhibition",
    description: "The science exhibition is for all schools students to exhibit their Innovations and research.",
  },
];

const carouselItems = [
  {
    id: 1,
    imageSrc: "/assets/seminar/3.jpg",
    altText: "Mountain",
    name: "Miss. Sprooha Athalye",
    date: "April 06, 2024",
    time: "11:00 pm",
  },
  {
    id: 2,
    imageSrc: "/assets/seminar/1.jpg",
    altText: "Mountain",
    name: "Mr.Dhawade Sir",
    date: "March 07, 2024",
    time: "12:00 pm",
  },
  {
    id: 3,
    imageSrc: "/assets/seminar/2.jpg",
    altText: "Mountain",
    name: "Prof.S.C Nawle",
    date: "January 24, 2024",
    time: "12:00 pm",
  },
];


function Event() {
  const [files, setFiles] = useState([0]);

  useEffect(() => {
    fetchFiles();
  }, []);

  const fetchFiles = async () => {
    try {
      const res = await axios.get('https://mitmdb.mitm.ac.in/api/files/files');
      setFiles(res.data);
    } catch (error) {
      console.error("Error fetching files", error);
    }
  };

  return (
    <section class="font-change flex justify-center flex-col md:flex-row md:space-x-[7rem] lg:space-x-[14rem] mx-2">
      <motion.div className="rounded-lg lg:h-[38rem] h-[33rem] w-auto md:w-[30rem] lg:w-[41rem] mt-10 mx-2"
          initial={{ y:-100,opacity:0 }}
          animate={{ y:0, opacity:1}}
          transition={{ ease: "easeIn", duration: 2 }}
      >
        <div class="faq-header mb-3">
          <h3 class=" text-[2em] font-semibold flex space-x-2">
            <span>MITM </span> <span class="text-blue-600">Events </span>
            <img src="assets/svg/event.png" class="mx-2 w-9 h-8 mt-2" alt="" />
            <button
              type="button"
              class="w-[5rem] h-[1.8rem] mt-[1rem] bg-blue-950  text-xs  text-white px-2 py-1 rounded-lg hover:bg-blue-800"
            >
              View all
            </button>
          </h3>
          <h3 class="text-slate-500 my-[0.4em]"></h3>
          <div class="w-[7.5em] h-[0.17em] ml-[0.2em] bg-blue-600"></div>
        </div>


        <Carousel slideInterval={5000}>
        {files.filter(file => file.image3).map(file => (
            <div key={file._id} className="flex justify-center items-center bg-gray-100 py-[3rem] px-[2rem] lg:px-[6rem]">
              <div className="bg-white rounded-lg shadow-lg overflow-hidden max-w-lg w-full">
                <img src={`https://mitmdb.mitm.ac.in/uploads/${file.image3}`}  className="w-full h-64 object-cover" />
                <div className="p-6">
                  <h2 className="text-2xl font-bold text-gray-800 mb-2">{file.eventTitle}</h2>
                  <p className="text-gray-700 leading-tight mb-4">{file.description}</p>
                  {file.link? (
                    <Link to={file.link} className="border border-slate-700 text-slate-700 px-2 py-1 rounded-lg hover:bg-slate-100">
                      View more
                    </Link>
                  ) : (
                    <button
                      type="button"
                      className="border border-slate-700 text-slate-700 px-2 py-1 rounded-lg hover:bg-slate-100"
                    >
                      View more
                    </button>
                  )}
                </div>
              </div>
            </div>
          ))}
        </Carousel>
      </motion.div>

      <motion.div className="rounded-lg lg:h-[38rem] h-[38rem] xl:[45rem] w-auto lg:w-[33rem] mt-20 md:mt-0 mx-1"
          initial={{ y:-100,opacity:0 }}
          animate={{ y:0, opacity:1}}
          transition={{ ease: "easeIn", duration: 2 }}
      >
        <div className="faq-header mt-[2rem]">
          <h3 className="text-[2em] font-semibold flex space-x-3">
            <span>MITM </span>
            <span className="text-blue-600">Seminars </span>
            <img
              src="assets/svg/graduation.png"
              className="mx-2 w-9 mt-2"
              alt=""
            />
            <button
              type="button"
              className="w-[5rem] h-[1.8rem] mt-[1rem] bg-blue-950 text-xs text-white px-2 py-1 rounded-lg hover:bg-blue-800"
            >
              View all
            </button>
          </h3>
          <h3 className="text-slate-500 my-[0.4em]">SEE UPCOMING SEMINARS</h3>
          <div className="w-[7.5em] h-[0.17em] ml-[0.2em] bg-blue-600"></div>
        </div>

        <Carousel slideInterval={5000}>
      {files.filter(file => file.image4).map(file => {
        // Split dateTime into date and time
        const [date, time] = file.dateTime.split('T');

        return (
          <div key={file._id} className="flex justify-center items-center bg-gray-100 py-[3rem] px-[4rem] lg:px-[6rem]">
            <div className="bg-white rounded-lg shadow-lg overflow-hidden max-w-lg w-full">
              <img
                src={`https://mitmdb.mitm.ac.in/uploads/${file.image4}`}
                className="w-full h-64 object-cover"
                alt="Uploaded"
              />
              <div className="p-6 text-center">
                <h2 className="text-2xl font-bold text-gray-800 mb-2">{file.seminarname}</h2>
                <div className="flex justify-center items-center">
                  <img
                    src="assets/svg/icons8-calendar-50.png"
                    className="w-5 h-5 m-1"
                    alt="Calendar Icon"
                  />
                  <p>{date}</p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="100"
                    height="100"
                    viewBox="0 0 50 50"
                    className="w-5 h-5 m-1 fill-[#FAB005]"
                  >
                    <path d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 24.984375 6.9863281 A 1.0001 1.0001 0 0 0 24 8 L 24 22.173828 A 3 3 0 0 0 22 25 A 3 3 0 0 0 22.294922 26.291016 L 16.292969 32.292969 A 1.0001 1.0001 0 1 0 17.707031 33.707031 L 23.708984 27.705078 A 3 3 0 0 0 25 28 A 3 3 0 0 0 28 25 A 3 3 0 0 0 26 22.175781 L 26 8 A 1.0001 1.0001 0 0 0 24.984375 6.9863281 z"></path>
                  </svg>
                  <p>{time}</p>
                </div>
                <button
                  type="button"
                  className="border border-slate-700 text-slate-700 px-2 py-1 mt-3 rounded-lg hover:bg-slate-100"
                >
                  View details
                </button>
              </div>
            </div>
          </div>
        );
      })}
    </Carousel>
      </motion.div>
    </section>
  );
}

export default Event;
