import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import axios from 'axios';

function Slider() {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [files, setFiles] = useState([]);

  // Fetch files when component mounts
  useEffect(() => {
    const fetchFiles = async () => {
      try {
        // Update the URL to the production server's subdomain
        const res = await axios.get('https://mitmdb.mitm.ac.in/api/files/files');
        if (res.data && Array.isArray(res.data)) {
          const validFiles = res.data.filter(file => file.image1); // Filter out any files without image1
          setFiles(validFiles);
          if (validFiles.length === 0) {
            console.error("No valid images found");
          }
        } else {
          console.error("Unexpected response format", res.data);
        }
      } catch (error) {
        console.error("Error fetching files", error);
      }
    };

    fetchFiles();
  }, []);

  // Auto-slide images
  useEffect(() => {
    if (files.length > 0) {
      const interval = setInterval(() => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % files.length);
      }, 10000); // Change image every 10 seconds

      return () => clearInterval(interval); // Clean up interval on component unmount
    }
  }, [files]);

  // Go to the next image
  const nextImage = () => {
    if (files.length > 0) {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % files.length);
    }
  };

  // Go to the previous image
  const prevImage = () => {
    if (files.length > 0) {
      setCurrentImageIndex((prevIndex) => (prevIndex - 1 + files.length) % files.length);
    }
  };

  if (files.length === 0) return <div>No images available</div>; // Display message if no files

  // Check if the current image exists
  const currentFile = files[currentImageIndex];
  // Use the production server URL for the image source
  const imageSrc = currentFile ? `https://mitmdb.mitm.ac.in/uploads/${currentFile.image1}` : '';

  return (
    <div className="relative w-full h-48 md:h-[27rem] xl:h-[100vh]">
      <AnimatePresence initial={false}>
        {currentFile && currentFile.image1 ? (
          <motion.img
            key={currentImageIndex}
            src={imageSrc}
            className="object-cover w-full absolute top-0 left-0"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 2 }}
            onError={() => console.error('Image failed to load')} // Handle image load error
          />
        ) : (
          <div>
            {/* <div className="w-full h-full absolute top-0 left-0 bg-gray-300 flex items-center justify-center"> */}
            {/* <span>No Image Available</span> */}
          </div>
        )}
      </AnimatePresence>
      <button
        onClick={prevImage}
        className="absolute top-1/2 left-4 md:left-8 xl:left-12 transform -translate-y-1/2 rounded-full px-3 py-3 bg-white/15 bg-opacity-50 hover:scale-110 duration-300"
      >
        <img src="/assets/svg/left.svg" className='w-10 h-10' alt="Previous" />
      </button>
      <button
        onClick={nextImage}
        className="absolute top-1/2 right-4 md:right-8 xl:right-12 transform -translate-y-1/2 px-3 py-3 rounded-full bg-white/15 bg-opacity-50 hover:scale-110 duration-300"
      >
        <img src="/assets/svg/right.svg" className='w-10 h-10' alt="Next" />
      </button>
    </div>
  );
}

export default Slider;
