import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Proudalumni = () => {
  const [files, setFiles] = useState([]);

  useEffect(() => {
    fetchFiles();
  }, []);

  const fetchFiles = async () => {
    try {
      const res = await axios.get('https://mitmdb.mitm.ac.in/api/files/files');
      setFiles(res.data);
    } catch (error) {
      console.error("Error fetching files", error);
    }
  };

  return (
    <section className='m-5'>
      <div className="grid grid-cols-2 gap-4 md:grid-cols-4">
        {files.filter(file => file.image5).map(file => (
          <div key={file._id} className="grid gap-4">
            <div>
              <img
                className="h-auto max-w-full rounded-lg object-cover object-center"
                src={`https://mitmdb.mitm.ac.in/uploads/${file.image5}`}
                alt="gallery-photo"
              />
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Proudalumni;
