import React, { useState } from "react";
import { Modal } from "flowbite-react";
import { Button, Input, Textarea } from "@material-tailwind/react";
import { Toaster, toast } from "react-hot-toast";

function Searchicon() {
  const [openModal, setOpenModal] = useState(false);
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState(false);

  const onCloseModal = () => {
    setOpenModal(false);
    setEmailError(false);
    setPhoneNumberError(false);
  };

  const onSubmit = async (event) => {
    event.preventDefault();

    // Check if email is valid
    if (!validateEmail(email)) {
      setEmailError(true);
      return;
    }

    // Check if phone number is valid
    if (!validatePhoneNumber(phoneNumber)) {
      setPhoneNumberError(true);
      return;
    }

    const formData = new FormData(event.target);
    formData.append("access_key", "a64565bf-dc1a-40a1-ade6-e3900afd3e10");

    const object = Object.fromEntries(formData);
    const json = JSON.stringify(object);

    const res = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: json,
    }).then((res) => res.json());

    if (res.success) {
      toast.success("Successfully Submmited !");
      setTimeout(() => {}, 3000);
      onCloseModal();
    }
  };

  const validateEmail = (email) => {
    // Regular expression for email validation
    const re =
      // eslint-disable-next-line no-useless-escape
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const validatePhoneNumber = (phoneNumber) => {
    // Regular expression for phone number validation
    const re = /^[0-9]{10}$/;
    return re.test(String(phoneNumber));
  };

  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      <button
        title="Contact Sale"
        className="fixed z-30 bottom-10 right-8 bg-blue-600 px-2  md:h-16  h-14 rounded-full  shadow-xl drop-shadow-lg flex justify-center items-center text-white text-4xl hover:bg-blue-700 hover:drop-shadow-2xl hover:scale-110 duration-300"
        onClick={() => setOpenModal(true)}
      >
        {/* <span className="mb-2 lg:mb-0">&#9993;</span> */}
        <span className="text-base">Admission Enquiry</span>
        
      </button>
      <Modal show={openModal} size="md" onClose={onCloseModal} popup>
        <Modal.Header />
        <Modal.Body>
          <h2 className="text-center text-xl font-semibold my-3">Enquiry</h2>
          <form onSubmit={onSubmit} className="space-y-6">
            <Input
              label="Your name"
              type="text"
              name="name"
              required
              onChange={(e) => setEmail(e.target.value)}
              className="!outline-none !ring-0"
            />
            <Input
              label="Email"
              type="email"
              name="email"
              error={emailError}
              required
              onChange={(e) => setEmail(e.target.value)}
              className="!outline-none !ring-0"
            />
            {emailError && (
              <p className="text-red-500">Please enter a valid email.</p>
            )}
            <Input
              label="Phone Number"
              type="tel"
              name="phone"
              required
              error={phoneNumberError}
              onChange={(e) => setPhoneNumber(e.target.value)}
              className="!outline-none !ring-0"
            />
            {phoneNumberError && (
              <p className="text-red-500">Please enter a valid phone number.</p>
            )}
            <Textarea label="Enter your message" name="message" className="!outline-none !ring-0" />
            <Button className="bg-blue-700 text-white" type="submit">
              Submit
            </Button>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Searchicon;
