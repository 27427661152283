import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useAuth } from "./AuthProvider";

const Dashnews = () => {
  const { profile, logOut } = useAuth();
  const [files, setFiles] = useState([]);
  const [newsMessage, setNewsMessage] = useState('');
  const [newsmarkee, setNewsmarkee] = useState('');
  const [markeelink, setMarkeelink] = useState('');
  const [newsLink, setNewsLink] = useState('');
  const [updateMessages, setUpdateMessages] = useState({});

  useEffect(() => {
    fetchFiles();
  }, []);

  const fetchFiles = async () => {
    const res = await axios.get('https://mitmdb.mitm.ac.in/api/files/files');
    setFiles(res.data);
  };

  const handleUpload = async () => {
    const formData = new FormData();
    formData.append('newsMessage', newsMessage);
    formData.append('newsLink', newsLink);
    formData.append('newsmarkee', newsmarkee);
    formData.append('markeelink', markeelink);

    await axios.post('https://mitmdb.mitm.ac.in/api/files/upload', formData);
    fetchFiles();
  };

  const handleDelete = async (id) => {
    await axios.delete(`https://mitmdb.mitm.ac.in/api/files/files/${id}`);
    fetchFiles();
  };

  const handleUpdate = async (id) => {
    if (updateMessages[id]) {
      await axios.put(`https://mitmdb.mitm.ac.in/api/files/files/${id}`, {
        newsMessage: updateMessages[id],
      });
      fetchFiles();
    }
  };

  const handleUpdateMessageChange = (id, message) => {
    setUpdateMessages({
      ...updateMessages,
      [id]: message,
    });
  };

  return (
    <div className='mx-3'>
      <div className="h-14 flex justify-between items-center font-change bg-[#c5cbe7] my-4 rounded-t-xl">
        <h1 className="text-2xl font-semibold text-[#151d3f] px-3">ADMIN PANEL</h1>
        {profile && (
          <div className="flex flex-row justify-center items-center space-x-2 h-14 mx-2">
            <img
              src={profile.picture}
              alt={profile.name}
              className="h-10 w-10 rounded-full"
            />
            {/* <span className="font-bold">{profile.name}</span> */}
            <span className="hidden md:block">{profile.email}</span>
            <button
              onClick={logOut}
              className="bg-black my-2 px-6 py-1 text-white rounded-3xl mx-4 hover:bg-gray-800 hover:scale-105 duration-300"
            >
              Logout
            </button>
          </div>
        )}
      </div>

      <div className='mb-14 mx-auto flex justify-center text-sm md:text-base my-4'>
        <Link to='/dashboard' className='md:px-8 py-3 px-4  bg-blue-900 hover:bg-blue-800 text-white rounded-l-lg'>IMAGE's</Link>
        <Link to='/dashpdf' className='md:px-8 py-3 px-4 bg-blue-900 hover:bg-blue-800 text-white'>PDF's</Link>
        <Link to='/dashnews' className='md:px-8 py-3 px-4 bg-blue-600 text-white rounded-r-lg'>News</Link>
      </div>
      <p className='italic text-red-600 text-center'>(Important : Make sure when an uploading messages the all other fields/Inputs should be clear and upload single message at the time.)</p>

      <section className='my-3'>
        <div className="my-10 flex flex-col justify-center items-center space-y-3">
          <h2 className="text-3xl">News Update</h2>
          <div className="flex border flex-wrap border-black rounded-r-md">
            <input type="text" placeholder="News" onChange={(e) => setNewsMessage(e.target.value)} />
            <input type="text" placeholder="News Link" onChange={(e) => setNewsLink(e.target.value)} />
            <button className="bg-blue-900 text-white w-[4rem] py-1 hover:scale-105" onClick={handleUpload}>Add</button>
          </div>
        </div>

        <ul className="flex flex-col justify-center  space-x-2">
          {files.map(file => (
            <React.Fragment key={file._id}>
              {file.newsMessage && (
                <li key={`${file._id}-newsMessage`} className="flex flex-col justify-center items-center space-y-2">
                  <p>News : {file.newsMessage}</p>
                  <a href={file.newsLink}>News Link : {file.newsLink}</a>
              
                  <input
                    type="text"
                    placeholder="Update Titles"
                    value={updateMessages[file._id] || ''}
                    onChange={(e) => handleUpdateMessageChange(file._id, e.target.value)}
                  />
                  <button
                    className="bg-blue-900 text-white rounded-md w-[4rem] py-1 hover:scale-105"
                    onClick={() => handleUpdate(file._id)}
                  >
                    Update
                  </button>
                  <button
                    className="bg-red-700 text-white rounded-md w-[4rem] py-1 hover:scale-105"
                    onClick={() => handleDelete(file._id)}
                  >
                    Delete
                  </button>
                </li>
              )}
            </React.Fragment>
          ))}
        </ul>


        {/* news messages end */}

        <div className="my-10 flex flex-col justify-center items-center space-y-3">
          <h2 className="text-3xl">Markee Update</h2>
          <p className='italic text-red-600'>(Give three times space e.g news___| )</p>
          <div className="flex flex-wrap border border-black rounded-r-md">
            <input type="text" placeholder="Markee News" onChange={(e) => setNewsmarkee(e.target.value)} />
            <input type="text" placeholder="Markee Link" onChange={(e) => setMarkeelink(e.target.value)} />
            <button className="bg-blue-900 text-white w-[4rem] py-1 hover:scale-105" onClick={handleUpload}>Add</button>
          </div>
        </div>

        <ul className="flex flex-col justify-center  space-x-2">
          {files.map(file => (
            <React.Fragment key={file._id}>
              {file.newsmarkee && (
                <li key={`${file._id}-newsMessage`} className="flex flex-col justify-center items-center space-y-2">
                  <p>News : {file.newsmarkee}</p>
                  <a href={file.markeelink}>News Link : {file.markeelink}</a>
                  <button
                    className="bg-red-700 text-white rounded-md w-[4rem] py-1 hover:scale-105"
                    onClick={() => handleDelete(file._id)}
                  >
                    Delete
                  </button>
                </li>
              )}
            </React.Fragment>
          ))}
        </ul>
      </section>
    </div>
  );
};

export default Dashnews;
