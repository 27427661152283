import React, { useState, useEffect } from 'react';
import axios from 'axios';

function Markee() {
  const [files, setFiles] = useState([]);

  useEffect(() => {
    fetchFiles();
  }, []);

  const fetchFiles = async () => {
    try {
      const res = await axios.get('https://mitmdb.mitm.ac.in/api/files/files');
      setFiles(res.data);
    } catch (error) {
      console.error("Error fetching files", error);
    }
  };


  const marqueeItems = [
    { text: 'Public Declaration, 11 Jan 2024.', link: '#' },
    { text: 'UG Programme: Weightage for Initial Selection Process', link: '#' },
    { text: 'UG Admissions 2024', link: 'https://docs.google.com/forms/d/e/1FAIpQLSe1S-fvJQO0qZ7TaoQpj_UK41qsj8-5lE7CfIgpzK1OBumorQ/viewform', target: '_blank' },
  ];
  return (
    <>
      <section id="notice ">
        <div className="notice font-change shadow-lg ">
          <div className="h-8 w-auto relative bg-blue-200 flex justify-center  m-auto shadow-inner -z-10">
            <div className="h-8 w-[30%] sm:w-[31%] left-0  absolute flex justify-end items-center bg-[#00072e] shadow-inner z-10">
              <span className="text-white  sm:mr-[2rem]  font-bold text-xs lg:text-[1rem] ml-1 sm:ml-0">
                Special Annnouncements
              </span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22px"
                height="22px"
                fill="currentColor"
                viewBox="0 0 16 16"
                style={{
                  right: `calc(22% - 61px)`,
                  top: "9.5px",
                  color: "#00072e",
                  margin: "-3px -0.7rem",
                }}
              >
                <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"></path>
              </svg>
            </div>

            <marquee
              className="overflow-hidden sm:w-[63%] w-[65%] ml-[7rem] lg:ml-[25rem] pt-[8px] bg-[hsl(21, 69%, 14%)] space-x-3"
              behavior="scroll"
              direction="left"
            >
              {files.map(file => (
                <React.Fragment key={file._id}>
                  <a href={file.markeelink} target='_blank' className="marquee-link ">
                    <span className="text-black ">
                      {file.newsmarkee} 
                      
                    </span>
                  </a>
                </React.Fragment>
              ))}
            </marquee>
            <div className="h-8 w-[10%] right-0 bg-blue-200 absolute shadow-inner hidden lg:block"></div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Markee;
