import { useAuth } from "./AuthProvider";
import { Link } from "react-router-dom";
import axios from "axios";
import React, { useState, useEffect } from 'react';

const Dashboard = () => {
  const { profile, logOut } = useAuth();
  const [files, setFiles] = useState([]); 
  const [carousel, setCarousel] = useState([]);
  const [seminar, setSeminar] = useState([]);
  const [update, setUpdate] = useState([]);
  const [images, setImages] = useState(Array(5).fill(null));
  const [pdfs, setPdfs] = useState(Array(5).fill(null));
  const [description, setDescription] = useState('');
  const [eventTitle, setEventTitle] = useState('');
  const [updatedescription, setUpdatedescription] = useState('');
  const [dateTime, setDateTime] = useState('');
  const [seminarname, setSeminarname] = useState('');

  useEffect(() => {
    fetchFiles();
  }, []);

  const fetchFiles = async () => {
    const res = await axios.get('https://mitmdb.mitm.ac.in/api/files/files');
    setFiles(res.data);
    setCarousel(res.data);
    setUpdate(res.data);
    setSeminar(res.data);
  };

  const handleImageChange = (index, e) => {
    const newImages = [...images];
    newImages[index] = e.target.files[0];
    setImages(newImages);
  };

  const handleUpload = async () => {
    const formData = new FormData();
    images.forEach((image, index) => {
      if (image) formData.append(`image${index + 1}`, image);
    });
    pdfs.forEach((pdf, index) => {
      if (pdf) formData.append(`pdf${index + 1}`, pdf);
    });

    formData.append('description', description);
    formData.append('eventTitle', eventTitle);
    formData.append('seminarname', seminarname);
    formData.append('dateTime', dateTime);
    await axios.post('https://mitmdb.mitm.ac.in/api/files/upload', formData);
    fetchFiles();
  };

  const handleDelete = async (id) => {
    await axios.delete(`https://mitmdb.mitm.ac.in/api/files/files/${id}`);
    fetchFiles();
  };

  const handleUpdate = async (id) => {
    await axios.put(`https://mitmdb.mitm.ac.in/api/files/files/${id}`, { description: updatedescription });
    fetchFiles();
  };

  return (
    <div className="mx-3">
      <div className="h-14 flex justify-between items-center font-change bg-[#c5cbe7] my-4 rounded-t-xl ">
        <h1 className="text-2xl font-semibold text-[#151d3f] px-3">ADMIN PANEL</h1>
        {profile && (
          <div className="flex flex-row justify-center items-center space-x-2 h-14 mx-2 ">
            <img
              src={profile.picture}
              alt={profile.name}
              className="h-10 w-10 rounded-full"
            />
            <span className="hidden md:block">{profile.email}</span>
            <button
              onClick={logOut}
              className="bg-black my-2 px-6 py-1 text-white rounded-3xl mx-4 hover:bg-gray-800 hover:scale-105 duration-300"
            >
              Logout
            </button>
          </div>
        )}
      </div>

      <div className='mb-14 mx-auto flex justify-center text-sm md:text-base my-4'>
        <Link to='/dashboard' className='md:px-8 py-3 px-4 bg-blue-600 text-white rounded-l-lg '>IMAGE's</Link>
        <Link to='/dashpdf' className='md:px-8 py-3 px-4 bg-blue-900 hover:bg-blue-800 text-white '>PDF's</Link>
        <Link to='/dashnews' className='md:px-8 py-3 px-4 bg-blue-900 hover:bg-blue-800 text-white rounded-r-lg'>News</Link>
      </div>
      <p className='italic text-red-600 text-center'>(Important : Make sure when an uploading images the all other fields/Inputs should be clear and upload single image at the time.)</p>

      <section className="my-6">
        <div className="my-10 flex flex-col justify-center items-center space-y-3">
          <h2 className="text-3xl">Main Carousel Images</h2>
          <p className='italic text-red-600'>(Size should be 900 x 380 )</p>
          <div className="flex flex-wrap border border-black rounded-r-md">
            <input type="file" accept="image/*" onChange={(e) => handleImageChange(0, e)} required />
            <button className="bg-blue-900 text-white w-[4rem] py-1 hover:scale-105" onClick={handleUpload}>Add</button>
          </div>
        </div>
        <ul className="flex flex-row space-x-2">
          {carousel.map(file => (
            <React.Fragment key={file._id}>
              {file.image1 && (
                <li key={`${file._id}-image1`} className="flex flex-col flex-wrap justify-center items-center space-y-2">
                  <img className="h-[5rem]" src={`https://mitmdb.mitm.ac.in/uploads/${file.image1}`} alt="Uploaded" />
                  <button className="bg-red-700 text-white rounded-md w-[4rem] py-1 hover:scale-105" onClick={() => handleDelete(file._id)}>Delete</button>
                </li>
              )}
            </React.Fragment>
          ))}
        </ul>

        {/* carousel end  */}

        <div className="my-10 flex flex-col justify-center items-center space-y-3">
          <h2 className="text-3xl">Flyers/Update Section Images</h2>
          <p className='italic text-red-600'>(Size should be 1600 x 1060 )</p>
          <div className="flex flex-wrap border border-black rounded-r-md">
            <input type="file" accept="image/*" onChange={(e) => handleImageChange(1, e)} required />
            <button className="bg-blue-900 text-white w-[4rem] py-1 hover:scale-105" onClick={handleUpload}>Add</button>
          </div>
        </div>
        <ul className="flex flex-row space-x-2">
          {seminar.map(file => (
            <React.Fragment key={file._id}>
              {file.image2 && (
                <li key={`${file._id}-image2`} className="flex flex-wrap flex-col justify-center items-center space-y-2">
                  <img className="h-[5rem]" src={`https://mitmdb.mitm.ac.in/uploads/${file.image2}`} alt="Uploaded" />
                  <button className="bg-red-700 text-white rounded-md w-[4rem] py-1 hover:scale-105" onClick={() => handleDelete(file._id)}>Delete</button>
                </li>
              )}
            </React.Fragment>
          ))}
        </ul>

        {/* updates end */}

        <div className="my-10 flex flex-col justify-center items-center space-y-3">
          <h2 className="text-3xl">Events Section Images</h2>
          <p className='italic text-red-600'>(Size should be 3000 x 2000 )</p>
          <div className="flex flex-wrap border border-black rounded-r-md">
            <input type="file" accept="image/*" onChange={(e) => handleImageChange(2, e)} required />
            <input type="text" placeholder="Title" value={eventTitle} onChange={(e) => setEventTitle(e.target.value)} />
            <input type="text" placeholder="Description" value={description} onChange={(e) => setDescription(e.target.value)} />
            <button className="bg-blue-900 text-white w-[4rem] py-1 hover:scale-105" onClick={handleUpload}>Add</button>
          </div>
        </div>

        <ul className="flex flex-row space-x-2">
          {update.map(file => (
            <React.Fragment key={file._id}>
              {file.image3 && (
                <li key={`${file._id}-image3`} className="flex flex-wrap flex-col justify-center items-center space-y-2">
                  <img className="h-[5rem]" src={`https://mitmdb.mitm.ac.in/uploads/${file.image3}`} alt="Uploaded" />
                  <p>Title: {file.eventTitle}</p>
                  <p>Description: {file.description}</p>
                  <input type="text" placeholder="Update Description" value={updatedescription} onChange={(e) => setUpdatedescription(e.target.value)} />
                  <button className="bg-blue-900 text-white rounded-md w-[4rem] py-1 hover:scale-105" onClick={() => handleUpdate(file._id)}>Update</button>
                  <button className="bg-red-700 text-white rounded-md w-[4rem] py-1 hover:scale-105" onClick={() => handleDelete(file._id)}>Delete</button>
                </li>
              )}
            </React.Fragment>
          ))}
        </ul>

        {/* events end  */}

        <div className="my-10 flex  flex-col justify-center items-center space-y-3">
          <h2 className="text-3xl">Seminar section</h2>
          <p className='italic text-red-600'>(Size should be 1400 x 1220 )</p>
          <div className="flex border flex-wrap border-black rounded-r-md">
            <input type="file" accept="image/*" onChange={(e) => handleImageChange(3, e)} required />
            <input type="text" placeholder="Mr/Ms Lecturer name" onChange={(e) => setSeminarname(e.target.value)} />
            <input type="datetime-local" onChange={(e) => setDateTime(e.target.value)} />
            <button className="bg-blue-900 text-white w-[4rem] py-1 hover:scale-105" onClick={handleUpload}>Add</button>
          </div>
        </div>
        <ul className="flex flex-row space-x-2">
          {seminar.map(file => {
            if (!file.image4) return null;

            // Split dateTime into date and time
            const [date, time] = file.dateTime.split('T');
            // Optionally, format time if needed
            const formattedTime = time ? time.split('.')[0] : '';

            return (
              <React.Fragment key={file._id}>
                <li key={`${file._id}-image4`} className="flex flex-wrap flex-col justify-center items-center space-y-2">
                  <img className="h-[5rem]" src={`https://mitmdb.mitm.ac.in/uploads/${file.image4}`} alt="Uploaded" />
                  <h2>{file.seminarname}</h2>
                  <div>Date: {date}</div>
                  <div>Time: {formattedTime}</div>
                  <button className="bg-red-700 text-white rounded-md w-[4rem] py-1 hover:scale-105" onClick={() => handleDelete(file._id)}>Delete</button>
                </li>
              </React.Fragment>
            );
          })}
        </ul>
        {/* Seminar end  */}

        <div className="my-10 flex flex-col justify-center items-center space-y-3">
          <h2 className="text-3xl">Alumni Section Images</h2>
          <p className='italic text-red-600'>(Auto adjustable Size )</p>
          <div className="flex border flex-wrap border-black rounded-r-md">
            <input type="file" accept="image/*" onChange={(e) => handleImageChange(4, e)} required />
            <button className="bg-blue-900 text-white w-[4rem] py-1 hover:scale-105" onClick={handleUpload}>Add</button>
          </div>
        </div>
        <ul className="flex flex-row space-x-2">
          {seminar.map(file => (
            <React.Fragment key={file._id}>
              {file.image5 && (
                <li key={`${file._id}-image5`} className="flex flex-wrap flex-col justify-center items-center space-y-2">
                  <img className="h-[5rem]" src={`https://mitmdb.mitm.ac.in/uploads/${file.image5}`} alt="Uploaded" />
                  <button className="bg-red-700 text-white rounded-md w-[4rem] py-1 hover:scale-105" onClick={() => handleDelete(file._id)}>Delete</button>
                </li>
              )}
            </React.Fragment>
          ))}
        </ul>

        {/* Alumni updates end */}
      </section>
    </div>
  );
};

export default Dashboard;
