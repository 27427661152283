import React, { useState, useEffect } from 'react';
import axios from 'axios';
import "../styles/markee.css"
import { Carousel } from "flowbite-react";
import { motion } from "framer-motion";



function News() {
  const [files, setFiles] = useState([0]);
  const [news, setNews] = useState([]);
  useEffect(() => {
    fetchFiles();
  }, []);

  const fetchFiles = async () => {
    try {
      const res = await axios.get('https://mitmdb.mitm.ac.in/api/files/files');
      setFiles(res.data);
      setNews(res.data);
    } catch (error) {
      console.error("Error fetching files", error);
    }
  };

  const hasImages = files.some(file => file.image2);
  return (
    <section className="flex flex-col lg:flex-row items-center justify-center lg:space-x-[8rem]">
    <motion.div
        className="leftOut h-[19rem] sm:h-[23rem] w-[82%] md:h-[26rem] xl:h-[30rem] lg:w-[50%] xl:w-[40%] bg-blue-100 p-6 mx-6 lg:mt-2 mt-14 rounded-lg text-center shadow-xl font-change"
        initial={{ y: -100, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ ease: "easeIn", duration: 2 }}
      >
        <h3 className="text-[1.5rem] font-semibold flex space-x-2">
          <span>UPDATES </span>
          <img
            src="assets/svg/clock.png"
            className="mx-2 w-6 h-6 mt-2"
            alt="clock icon"
          />
          <button
            type="button"
            className="w-[5rem] h-[1.8rem] mt-[0.4rem] bg-blue-900 text-xs text-white px-2 py-1 rounded-lg hover:bg-blue-800 hover:scale-110 duration-300"
          >
            View all
          </button>
        </h3>

        {hasImages ? (
          <div className="h-56 sm:h-64 md:h-80 2xl:h-96 md:mt-3">
            <Carousel slideInterval={5000}>
            {files.filter(file => file.image2).map(file => (
            <img
              key={file._id}
              src={`https://mitmdb.mitm.ac.in/uploads/${file.image2}`}
              className="md:h-[20rem] lg:h-[22rem]"
              alt={`Update ${file._id}`}
            />
          ))}
            </Carousel>
          </div>
        ) : (
          <p className="mt-4 text-gray-600">No updates available</p>
        )}
      </motion.div>
      <motion.div
        className="rightOut h-[22rem] lg:h-[30rem] w-[82%] lg:w-[30%] bg-blue-100 p-6 m-6 rounded-lg text-center shadow-xl relative font-change"
        initial={{ y: -100, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ ease: "easeIn", duration: 2 }}
      >
        <h3 className="text-[1.5rem] font-semibold flex space-x-2">
          <span>NEWS </span>
          <img
            src="assets/svg/newspaper.png"
            className="mx-2 w-6 h-6 mt-2"
            alt="newspaper icon"
          />
          <button
            type="button"
            className="w-[5rem] h-[1.8rem] mt-[0.4rem] bg-blue-900 text-xs text-white px-2 py-1 rounded-lg hover:bg-blue-800 hover:scale-110 duration-300"
          >
            View all
          </button>
        </h3>
        
        <div className="overflow-hidden h-[17rem] lg:h-[25rem] mt-4 relative">
          <div className="overflow-hidden h-[25rem] mt-4 relative">
            <div className="marquee-container">
              <div className="marquee">
                <div className="item flex flex-col w-full text-red-600 font-semibold text-start">
                {files.filter(file => file.newsMessage).map(file => (
                  <div key={file._id} className="m-3 flex">
                    <a href={file.newsLink} target='_blank'>{file.newsMessage}</a>
                    {/* {file.imgSrc && <img src={file.imgSrc} className="h-8 ml-2" alt="new" />} */}
                  </div>
                ))}
                </div>
              </div>
            </div>
          </div>
        </div>
       
      </motion.div>
    </section>
  );
}

export default News;



