import axios from "axios";
import React, { useState, useEffect } from 'react';
import { Table } from "flowbite-react";
import { motion } from "framer-motion";

function Timetable() {
  const [files, setFiles] = useState([]);

  useEffect(() => {
    fetchFiles();
  }, []);

  const fetchFiles = async () => {
    try {
      const res = await axios.get('https://mitmdb.mitm.ac.in/api/files/files');
      setFiles(res.data);
    } catch (error) {
      console.error("Error fetching files", error);
    }
  };


  return (
    <motion.section className="md:px-32 px-3 py-6"
    initial={{ y:-40,opacity:0 }}
          animate={{ y:0, opacity:1}}
          transition={{ ease: "easeIn", duration: 1 }}>
    
          
      <h1 className="text-xl underline underline-offset-8 font-bold  my-3 text-center">
        DEGREE TIME TABLES
      </h1>
      <div className="overflow-x-auto my-7 border border-gray-200 rounded-xl">
        <Table>
          <Table.Head>
            <Table.HeadCell className="bg-blue-900 text-white text-xs md:text-lg">
              Sr.No.
            </Table.HeadCell>
            <Table.HeadCell className="bg-blue-900 text-white text-xs md:text-lg">
              COURSES
            </Table.HeadCell>
            <Table.HeadCell className="bg-blue-900 text-white text-xs md:text-lg">
              Downloads
            </Table.HeadCell>
          </Table.Head>
          <Table.Body className="divide-y font-bold">
            <Table.Row  className="bg-blue-50 hover:bg-white/95 dark:border-gray-700 dark:bg-gray-800">
              <Table.Cell>1</Table.Cell>
              <Table.Cell>Civil Engineering</Table.Cell>
          
              <Table.Cell className="text-lg flex space-x-2 text-red-600">
              {files.filter(file => file.civ1).map(file => (
              <div key={`${file._id}-civ1`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.civ1}`} target="_blank" rel="noopener noreferrer">Sem I /</a>
                  
                  </div>
              ))}
              {files.filter(file => file.civ2).map(file => (
              <div key={`${file._id}-civ2`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.civ2}`} target="_blank" rel="noopener noreferrer">Sem II /</a>
                  
                  </div>
              ))}
                    {files.filter(file => file.civ3).map(file => (
              <div key={`${file._id}-civ3`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.civ3}`} target="_blank" rel="noopener noreferrer">Sem III /</a>
                  
                  </div>
              ))}
                      {files.filter(file => file.civ4).map(file => (
              <div key={`${file._id}-civ4`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.civ4}`} target="_blank" rel="noopener noreferrer">Sem IV /</a>
                  
                  </div>
              ))}
                {files.filter(file => file.civ5).map(file => (
              <div key={`${file._id}-civ5`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.civ5}`} target="_blank" rel="noopener noreferrer">Sem V /</a>
                  
                  </div>
              ))}
              {files.filter(file => file.civ6).map(file => (
              <div key={`${file._id}-civ6`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.civ6}`} target="_blank" rel="noopener noreferrer">Sem VI /</a>
                  
                  </div>
              ))}
              {files.filter(file => file.civ7).map(file => (
              <div key={`${file._id}-civ7`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.civ7}`} target="_blank" rel="noopener noreferrer">Sem VII /</a>
                  
                  </div>
              ))}
              {files.filter(file => file.civ8).map(file => (
              <div key={`${file._id}-civ8`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.civ8}`} target="_blank" rel="noopener noreferrer">Sem VIII </a>
                  
                  </div>
              ))}
              </Table.Cell>
          
            </Table.Row>
            <Table.Row className="bg-blue-50 hover:bg-white/95 dark:border-gray-700 dark:bg-gray-800">
              <Table.Cell>2</Table.Cell>
              <Table.Cell>Mechanical Engineering</Table.Cell>

              <Table.Cell className="text-lg flex space-x-2 text-red-600">
              {files.filter(file => file.mac1).map(file => (
              <div key={`${file._id}-mac1`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.mac1}`} target="_blank" rel="noopener noreferrer">Sem I /</a>
                  
                  </div>
              ))}
              {files.filter(file => file.mac2).map(file => (
              <div key={`${file._id}-mac2`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.mac2}`} target="_blank" rel="noopener noreferrer">Sem II /</a>
                  
                  </div>
              ))}
                    {files.filter(file => file.mac3).map(file => (
              <div key={`${file._id}-mac3`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.mac3}`} target="_blank" rel="noopener noreferrer">Sem III /</a>
                  
                  </div>
              ))}
                      {files.filter(file => file.mac4).map(file => (
              <div key={`${file._id}-mac4`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.mac4}`} target="_blank" rel="noopener noreferrer">Sem IV /</a>
                  
                  </div>
              ))}
                {files.filter(file => file.mac5).map(file => (
              <div key={`${file._id}-mac5`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.mac5}`} target="_blank" rel="noopener noreferrer">Sem V /</a>
                  
                  </div>
              ))}
              {files.filter(file => file.mac6).map(file => (
              <div key={`${file._id}-mac6`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.mac6}`} target="_blank" rel="noopener noreferrer">Sem VI /</a>
                  
                  </div>
              ))}
              {files.filter(file => file.mac7).map(file => (
              <div key={`${file._id}-mac7`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.mac7}`} target="_blank" rel="noopener noreferrer">Sem VII /</a>
                  
                  </div>
              ))}
              {files.filter(file => file.mac8).map(file => (
              <div key={`${file._id}-mac8`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.mac8}`} target="_blank" rel="noopener noreferrer">Sem VIII </a>
                  
                  </div>
              ))}
              </Table.Cell>
            </Table.Row>
            <Table.Row className="bg-blue-50 hover:bg-white/95 dark:border-gray-700 dark:bg-gray-800">
              <Table.Cell>3</Table.Cell>
              <Table.Cell>
                Electronics & Telecommunication Engineering
              </Table.Cell>

              <Table.Cell className="text-lg text-red-600">
              {files.filter(file => file.extc1).map(file => (
              <div key={`${file._id}-extc1`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.extc1}`} target="_blank" rel="noopener noreferrer">Sem I /</a>
                  
                  </div>
              ))}
              {files.filter(file => file.extc2).map(file => (
              <div key={`${file._id}-extc2`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.extc2}`} target="_blank" rel="noopener noreferrer">Sem II /</a>
                  
                  </div>
              ))}
                    {files.filter(file => file.extc3).map(file => (
              <div key={`${file._id}-extc3`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.extc3}`} target="_blank" rel="noopener noreferrer">Sem III /</a>
                  
                  </div>
              ))}
                      {files.filter(file => file.extc4).map(file => (
              <div key={`${file._id}-extc4`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.extc4}`} target="_blank" rel="noopener noreferrer">Sem IV /</a>
                  
                  </div>
              ))}
                {files.filter(file => file.extc5).map(file => (
              <div key={`${file._id}-extc5`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.extc5}`} target="_blank" rel="noopener noreferrer">Sem V /</a>
                  
                  </div>
              ))}
              {files.filter(file => file.extc6).map(file => (
              <div key={`${file._id}-extc6`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.extc6}`} target="_blank" rel="noopener noreferrer">Sem VI /</a>
                  
                  </div>
              ))}
              {files.filter(file => file.extc7).map(file => (
              <div key={`${file._id}-extc7`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.extc7}`} target="_blank" rel="noopener noreferrer">Sem VII /</a>
                  
                  </div>
              ))}
              {files.filter(file => file.extc8).map(file => (
              <div key={`${file._id}-extc8`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.extc8}`} target="_blank" rel="noopener noreferrer">Sem VIII </a>
                  
                  </div>
              ))}
              </Table.Cell>
            </Table.Row>

            <Table.Row className="bg-blue-50 hover:bg-white/95 dark:border-gray-700 dark:bg-gray-800">
              <Table.Cell>4</Table.Cell>
              <Table.Cell>Computer Engineering</Table.Cell>

              <Table.Cell className="text-lg text-red-600">
              {files.filter(file => file.pdf1).map(file => (
              <div key={`${file._id}-pdf1`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.pdf1}`} target="_blank" rel="noopener noreferrer">Sem I /</a>
                  
                  </div>
              ))}
              {files.filter(file => file.pdf2).map(file => (
              <div key={`${file._id}-pdf2`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.pdf2}`} target="_blank" rel="noopener noreferrer">Sem II /</a>
                  
                  </div>
              ))}
                    {files.filter(file => file.pdf3).map(file => (
              <div key={`${file._id}-pdf3`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.pdf3}`} target="_blank" rel="noopener noreferrer">Sem III /</a>
                  
                  </div>
              ))}
                      {files.filter(file => file.pdf4).map(file => (
              <div key={`${file._id}-pdf4`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.pdf4}`} target="_blank" rel="noopener noreferrer">Sem IV /</a>
                  
                  </div>
              ))}
                {files.filter(file => file.pdf5).map(file => (
              <div key={`${file._id}-pdf5`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.pdf5}`} target="_blank" rel="noopener noreferrer">Sem V /</a>
                  
                  </div>
              ))}
              {files.filter(file => file.pdf6).map(file => (
              <div key={`${file._id}-pdf6`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.pdf6}`} target="_blank" rel="noopener noreferrer">Sem VI /</a>
                  
                  </div>
              ))}
              {files.filter(file => file.pdf7).map(file => (
              <div key={`${file._id}-pdf7`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.pdf7}`} target="_blank" rel="noopener noreferrer">Sem VII /</a>
                  
                  </div>
              ))}
              {files.filter(file => file.pdf8).map(file => (
              <div key={`${file._id}-pdf8`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.pdf8}`} target="_blank" rel="noopener noreferrer">Sem VIII </a>
                  
                  </div>
              ))}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </div>

      <h1 className="text-xl underline underline-offset-8 font-bold  my-3 text-center">
        DIPLOMA ENGINEERING TIME TABLES
      </h1>
      <div className="overflow-x-auto mb-14 mt-8 border border-gray-200 rounded-xl">
        <Table>
          <Table.Head>
            <Table.HeadCell className="bg-blue-900 text-white text-xs md:text-lg">
              Sr.No.
            </Table.HeadCell>
            <Table.HeadCell className="bg-blue-900 text-white text-xs md:text-lg">
              COURSES
            </Table.HeadCell>
            <Table.HeadCell className="bg-blue-900 text-white text-xs md:text-lg">
              DOWNLOADS
            </Table.HeadCell>
          </Table.Head>
          <Table.Body className="divide-y font-bold">
            <Table.Row className="bg-blue-50 hover:bg-white/95 dark:border-gray-700 dark:bg-gray-800">
              <Table.Cell>1</Table.Cell>
              <Table.Cell>Mechanical Engineering</Table.Cell>

              <Table.Cell className="text-lg text-red-600">
              {files.filter(file => file.dmac1).map(file => (
              <div key={`${file._id}-dmac1`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.dmac1}`} target="_blank" rel="noopener noreferrer">Sem I /</a>
                  
                  </div>
              ))}
              {files.filter(file => file.dmac2).map(file => (
              <div key={`${file._id}-dmac2`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.dmac2}`} target="_blank" rel="noopener noreferrer">Sem II /</a>
                  
                  </div>
              ))}
                    {files.filter(file => file.dmac3).map(file => (
              <div key={`${file._id}-dmac3`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.dmac3}`} target="_blank" rel="noopener noreferrer">Sem III /</a>
                  
                  </div>
              ))}
                      {files.filter(file => file.dmac4).map(file => (
              <div key={`${file._id}-dmac4`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.dmac4}`} target="_blank" rel="noopener noreferrer">Sem IV /</a>
                  
                  </div>
              ))}
                {files.filter(file => file.dmac5).map(file => (
              <div key={`${file._id}-dmac5`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.dmac5}`} target="_blank" rel="noopener noreferrer">Sem V /</a>
                  
                  </div>
              ))}
              {files.filter(file => file.dmac6).map(file => (
              <div key={`${file._id}-dmac6`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.dmac6}`} target="_blank" rel="noopener noreferrer">Sem VI /</a>
                  
                  </div>
              ))}

              </Table.Cell>
            </Table.Row>

            <Table.Row className="bg-blue-50 hover:bg-white/95 dark:border-gray-700 dark:bg-gray-800">
              <Table.Cell>2</Table.Cell>
              <Table.Cell>Civil Engineering</Table.Cell>

              <Table.Cell className="text-lg text-red-600">
              {files.filter(file => file.dciv1).map(file => (
              <div key={`${file._id}-dciv1`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.dciv1}`} target="_blank" rel="noopener noreferrer">Sem I /</a>
                  
                  </div>
              ))}
              {files.filter(file => file.dciv2).map(file => (
              <div key={`${file._id}-dciv2`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.dciv2}`} target="_blank" rel="noopener noreferrer">Sem II /</a>
                  
                  </div>
              ))}
                    {files.filter(file => file.dciv3).map(file => (
              <div key={`${file._id}-dciv3`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.dciv3}`} target="_blank" rel="noopener noreferrer">Sem III /</a>
                  
                  </div>
              ))}
                      {files.filter(file => file.dciv4).map(file => (
              <div key={`${file._id}-dciv4`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.dciv4}`} target="_blank" rel="noopener noreferrer">Sem IV /</a>
                  
                  </div>
              ))}
                {files.filter(file => file.dciv5).map(file => (
              <div key={`${file._id}-dciv5`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.dciv5}`} target="_blank" rel="noopener noreferrer">Sem V /</a>
                  
                  </div>
              ))}
              {files.filter(file => file.dciv6).map(file => (
              <div key={`${file._id}-dciv6`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.dciv6}`} target="_blank" rel="noopener noreferrer">Sem VI /</a>
                  
                  </div>
              ))}

              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </div>

      <h1 className="text-xl underline underline-offset-8 font-bold  my-3 text-center">
        B.SC TIME TABLES
      </h1>
      <div className="overflow-x-auto mb-14 mt-8 border border-gray-200 rounded-xl">
        <Table>
          <Table.Head>
            <Table.HeadCell className="bg-blue-900 text-white text-xs md:text-lg">
              Sr.No.
            </Table.HeadCell>
            <Table.HeadCell className="bg-blue-900 text-white text-xs md:text-lg">
              COURSES
            </Table.HeadCell>
            <Table.HeadCell className="bg-blue-900 text-white text-xs md:text-lg">
              DOWNLOADS
            </Table.HeadCell>
          </Table.Head>
          <Table.Body className="divide-y font-bold">
            <Table.Row className="bg-blue-50 hover:bg-white/95 dark:border-gray-700 dark:bg-gray-800">
              <Table.Cell>1</Table.Cell>
              <Table.Cell>B.Sc (Computer Science)</Table.Cell>

              <Table.Cell className="text-lg text-red-600">
              {files.filter(file => file.bsc1).map(file => (
              <div key={`${file._id}-bsc1`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.bsc1}`} target="_blank" rel="noopener noreferrer">Sem I /</a>
                  
                  </div>
              ))}
              {files.filter(file => file.bsc2).map(file => (
              <div key={`${file._id}-bsc2`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.bsc2}`} target="_blank" rel="noopener noreferrer">Sem II /</a>
                  
                  </div>
              ))}
                    {files.filter(file => file.bsc3).map(file => (
              <div key={`${file._id}-bsc3`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.bsc3}`} target="_blank" rel="noopener noreferrer">Sem III /</a>
                  
                  </div>
              ))}
                      {files.filter(file => file.bsc4).map(file => (
              <div key={`${file._id}-bsc4`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.bsc4}`} target="_blank" rel="noopener noreferrer">Sem IV /</a>
                  
                  </div>
              ))}
                {files.filter(file => file.bsc5).map(file => (
              <div key={`${file._id}-bsc5`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.bsc5}`} target="_blank" rel="noopener noreferrer">Sem V /</a>
                  
                  </div>
              ))}
              {files.filter(file => file.bsc6).map(file => (
              <div key={`${file._id}-bsc6`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.bsc6}`} target="_blank" rel="noopener noreferrer">Sem VI /</a>
                  
                  </div>
              ))}

              </Table.Cell>
            </Table.Row>

            <Table.Row className="bg-blue-50 hover:bg-white/95 dark:border-gray-700 dark:bg-gray-800">
              <Table.Cell>2</Table.Cell>
              <Table.Cell>B.Sc (Information Technology)</Table.Cell>

              <Table.Cell className="text-lg text-red-600">
              {files.filter(file => file.bscit1).map(file => (
              <div key={`${file._id}-bscit1`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.bscit1}`} target="_blank" rel="noopener noreferrer">Sem I /</a>
                  
                  </div>
              ))}
              {files.filter(file => file.bscit2).map(file => (
              <div key={`${file._id}-bscit2`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.bscit2}`} target="_blank" rel="noopener noreferrer">Sem II /</a>
                  
                  </div>
              ))}
                    {files.filter(file => file.bscit3).map(file => (
              <div key={`${file._id}-bscit3`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.bscit3}`} target="_blank" rel="noopener noreferrer">Sem III /</a>
                  
                  </div>
              ))}
                      {files.filter(file => file.bscit4).map(file => (
              <div key={`${file._id}-bscit4`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.bscit4}`} target="_blank" rel="noopener noreferrer">Sem IV /</a>
                  
                  </div>
              ))}
                {files.filter(file => file.bscit5).map(file => (
              <div key={`${file._id}-bscit5`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.bscit5}`} target="_blank" rel="noopener noreferrer">Sem V /</a>
                  
                  </div>
              ))}
              {files.filter(file => file.bscit6).map(file => (
              <div key={`${file._id}-bscit6`}>
                    <a href={`https://mitmdb.mitm.ac.in/uploads/${file.bscit6}`} target="_blank" rel="noopener noreferrer">Sem VI /</a>
                  
                  </div>
              ))}

              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </div>
    </motion.section>
  );
}

export default Timetable;
